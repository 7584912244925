@import 'media-query.mixins';
@import 'util.mixins';
:host {
  --location-selector-near-me-button-background: unset;
}

.location-input {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  container-name: location-input-container;

  input {
    border: var(--locationSelectorInputBorder);
    border-bottom: var(--locationSelectorInputBorderBottom);
    border-radius: var(--inputBoxBorderRadius, var(--borderRadius));
    background-color: var(--locationSelectorInputBackgroundColor);
    padding: var(--locationSelectorInputPadding, 0 0.5rem);
    width: 100%;
    height: var(--filtersHeight, 35px);
    font-size: inherit;
  }
}

:host(.container-type-normal) .location-input {
  container-type: normal;
}

:host(.container-type-inline-size) .location-input {
  container-type: inline-size;
}

.location-input-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  fa-icon {
    width: min-content;
  }
}

button.ush-near-me-button {
  width: max-content;
  height: var(--filtersHeight, 35px);
  font-family: inherit;
  @if mixin-exists($name: locationSelectorNearMeMixin) {
    @include locationSelectorNearMeMixin;
  }

  background-color: var(--location-selector-near-me-button-background, var(--color-primary, var(--brandColor)));

  span {
    display: none;
  }

  fa-icon {
    display: flex;
    font-size: 20px;
  }

  @container location-input-container (width > #{$sm}) {
    fa-icon {
      display: none;
    }
    span {
      display: block;
    }
  }
}

.input-icon {
  display: flex;
  position: absolute;
  right: var(--locationSelectorInputIconRight, 10px);
  align-items: center;
  height: min-content;
  height: 100%;
  color: var(--locationSelectorIconColor);
  text-shadow: var(--locationSelectorIconTextShadow);

  &::before {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
