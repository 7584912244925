@use 'sass:math';

@mixin location-selector-with-marker() {
  ush-location-selector {
    .location-input {
      position: relative;
      align-items: center;
      input {
        padding-left: 40px !important;
        padding-right: 10px !important;
      }
    }
    .search-icon {
      display: none !important;
    }
    .marker-icon {
      display: flex !important;
      position: absolute !important;
      left: 10px !important;
      width: min-content;
      &::before {
        position: static !important;
        transform: none !important;
      }
    }
  }
}

/* ====== COMMON MIXINGS ======*/

@mixin shadow-gradient() {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 50%;
  width: 100%;
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.6) 60%, transparent);
  filter: opacity(1);
}

@mixin bordered-box($background: var(--backgroundColor), $borderColor: var(--brandColor)) {
  padding: 5%;
  background: $background;
  border: 2px solid $borderColor;
  @include for-tablet-landscape-up {
    padding: 8%;
  }
}

@mixin scrollbars($radius: none) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background: var(--brandColor);
  }

  &::-webkit-scrollbar-track {
    border-radius: $radius;
    background: rgba(0, 0, 0, 0.35);
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: var(--brandColor);
    scrollbar-track-color: rgba(0, 0, 0, 0.35);
  }
}
